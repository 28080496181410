import { Grid } from "@mui/material";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle } from "../styles";

const CurrentAccountContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          IV - CONTA-CORRENTE
           {/* - NÃO SE APLICA */}
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <MailingContractCheckbox
          label="Individual (OU) Agência __________ Conta-Corrente: __________________"
          description="Declaro ter sido informado e estar ciente de todos os termos e condições apresentados na PROPOSTA DE ABERTURA DE CONTA - PESSOA FÍSICA e nas CONDIÇÕES GERAIS PARA ABERTURA, MANUTENÇÃO, MOVIMENTAÇÃO E ENCERRAMENTO DE CONTA CORRENTE, as quais li, recebi e estão disponíveis também no site www.crefisa.com.br, declarando também que não tenho dúvidas sobre quaisquer de suas cláusulas e condições, aceitando-as, assim, na integralidade, às quais solicito minha adesão. Declaro que as informações constantes na FICHA CADASTRAL - PESSOA FÍSICA são verdadeiras e comprometo-me a mantê-las atualizadas, nos termos da legislação em vigor, bem como estou ciente de que referidas informações serão enviadas aos órgãos de proteção ao crédito e as empresas especializadas em processamento de cadastro bancário, as quais ficam autorizadas a fazer uso das referidas informações e dados em suas atividades. Declaro ter lido previamente os termos e condições do REGULAMENTO PARA CONTRATAÇÃO DO LIMITE DE CHEQUE ESPECIAL CONTA CORRENTE -PESSOA FÍSICA, disponível em www.crefisa.com.br, declarando também que não tenho dúvidas sobre quaisquer de suas cláusulas e condições, aceitando-o, assim, na integralidade. Outras condições estão detalhadas na fl 03 do presente Termo."
        />

        <MailingContractCheckbox checked label="Autorizo a análise dos meus dados para participar da campanha de elegibilidade ao produto Cheque Especial Conta Corrente - Pessoa Física." />
        <MailingContractCheckbox checked label="Autorizo a contratação do Limite de Cheque Especial Conta Corrente - Pessoa Física (sujeita a análise de crédito)." />
        <MailingContractCheckbox checked label="Autorizo a contratação do Adiantamento a Depositante (sujeita a análise de crédito)." />
        <MailingContractCheckbox label="Adesão ao limite de cheque especial não solicitado." description="O Anexo a PROPOSTA DE ABERTURA DE CONTAS – PESSOA FÍSICA PACOTES DE TARIFAS informa detalhadamente a composição dos Pacotes de Serviços ofertados aos clientes. Optando por um Pacote de Serviços, sua vigência será a partir do mês subsequente. Os valores cobrados pelos Pacotes de Serviços constam na Tabela de Tarifas divulgada pelo Banco Crefisa S/A em seu website www.crefisa.com.br.  Declaro que tenho conhecimento sobre o funcionamento e benefícios oferecidos pela minha adesão a um Pacote de Serviços e que decido neste momento:" />


        <MailingContractCheckbox label="Não aceitar a oferta de nenhum dos Pacotes de Serviços disponíveis nesta data, autorizando o Banco Crefisa S/A a cobrar as tarifas pelos serviços descritos na PROPOSTA DE ABERTURA DE CONTA - PESSOA FÍSICA." />
        <MailingContractCheckbox label="Autorizar minha adesão ao Pacote de Serviços Beneficiário do INSS." />
        <MailingContractCheckbox label="Autorizar minha adesão ao Pacote de Serviços I." />
        <MailingContractCheckbox label="Autorizar minha adesão ao Pacote de Serviços II." />
        <MailingContractCheckbox label="Autorizar minha adesão ao Pacote de Serviços III." />
        <MailingContractCheckbox label="Autorizar minha adesão ao Pacote de Serviços IV." />
        <MailingContractCheckbox  label="Autorizo o Banco Crefisa S/A a efetuar consultas nos órgãos de proteção ao crédito, serviços de prevenções a fraudes externos, bem como tenho ciência de que eventualmente poderão ocorrer comunicações ao Conselho de Controle de Atividades Financeiras - COAF, por questões relacionadas à movimentação de conta e a outros órgãos e entidades que venham a ser ou sejam previstas por norma legal para fins de inclusões e comunicações, assim como autorizo o Banco Crefisa S/A a consultar e validar meus dados para fins de fraudes e criação de cadastro de pontuação baseado no meu histórico de transações e a registrar e compartilhar dados e informações com instituições do sistema financeiro com a finalidade de subsidiar procedimentos e controles para prevenção de fraudes, nos termos da Resolução Conjunta n.º 6, de 2023, do BACEN/Conselho Monetário Nacional." />
        <MailingContractCheckbox  label="Autorizo o envio de informações relativas à movimentação ou lançamento em conta corrente por meio de mensagem automática.
" />

        {/* <MailingContractCheckbox label="Não aceitar a oferta de nenhum dos Pacotes de Serviços disponíveis nesta data." />

        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços Beneficiário do INSS." />
        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços I.  " />
        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços II.  " />
        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços III.  " />
        <MailingContractCheckbox
          label="Autorizar minha(nossa) adesão ao Pacote de Serviços IV.  "
          description="Optando por um Pacote de Serviços, sua vigência será a partir do mês subsequente. Os valores cobrados pelos Pacotes de Serviços constam na Tabela de Tarifas divulgada pelo Banco Crefisa S/A em seu website www.crefisa.com.br"
        />
        <MailingContractCheckbox
          label="Autorizo(amos) o Banco Crefisa S/A a efetuar consultas nos órgãos de Proteção ao Crédito, serviços de prevenções a fraudes
externos, bem como tenho(emos) ciência de que eventualmente poderão ocorrer comunicações ao Conselho de Controle de
Atividades Financeiras - COAF, por questões relacionadas à movimentação de conta, e a outros órgãos e entidades que venham a ser
ou sejam previstas por norma legal para fins de inclusões e comunicações.
"
        />
        <MailingContractCheckbox label="Autorizo(amos) o Banco Crefisa S/A a consultar e validar meus(nossos) dados para fins de fraudes e criação de cadastro de pontuação  baseado no meu(nosso) histórico de transações." />
        <MailingContractCheckbox label="Autorizo(amos), ainda, o Banco Crefisa S/A a fornecer, mediante solicitação e identificação do interessado, as informações previstas no artigo 6º, incisos I, II e III da da Resolução 3.972/2011, referentes a cheques devolvidos e/ou sustados." />
        <MailingContractCheckbox label="Autorizo(amos) o Banco Crefisa S/A a cobrar as tarifas pelos serviços descritos no item IV - Tarifas Bancárias da presente Proposta de Abertura de Conta" />
        <MailingContractCheckbox label="Autorizo(amos) o envio de informações relativas à movimentação ou lançamento em conta corrente por meio de mensagem automática." />
        <MailingContractCheckbox label="Autorizo(amos) o Banco Crefisa S/A E CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS a registrar e compartilhar dados e informações com instituições do sistema financeiro com a finalidade de subsidiar procedimentos e controles para prevenção de fraudes, nos termos da Resolução Conjunta 6, de 2023, do Ministério da Fazenda/Conselho Monetário Nacional." />
        <Stack alignItems="flex-end" marginTop="8px" marginRight="32px">
          <ContractSmallText>
            [1] CNPJ 61.033.106/0001-86, COM SEDE NA RUA CANADÁ, 390, SÃO
            PAULO/SP
          </ContractSmallText>
          <ContractSmallText>
            [2] CNPJ 60.779.196/0001-96, COM SEDE NA RUA CANADÁ, 387, SÃO
            PAULO/SP
          </ContractSmallText>
        </Stack> */}
      </Grid>
    </>
  );
};

export default CurrentAccountContractSection;
