import { LoopOutlined, PlayArrow, Stop, Warning } from "@mui/icons-material";
import { Button, Grid, Stack, IconButton, Box } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { MutableRefObject, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import useMicrophone from "../../../hooks/useMicrophone";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import useWebcam from "../../../hooks/useWebcam";
import useWhitelabel from "../../../hooks/useWhitelabel";

import {
  MailingData,
  MailingResponseData,
} from "../../../shared/models/backend/mailing/mailing";
import { FileTypeEnum } from "../../../shared/models/enums/files.enum";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import { Palette } from "../../../shared/theme/palette";
import useCamera from "../../onBoardingVideo/onBoardingVideo/hooks/useCamera";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import {
  BoldTypography,
  CenterBox,
  CircleBackground,
  MailingTextTypography,
  OnboardingCardLowRadius,
  RecordingLabel,
  SubTitle500Typography,
  SubTitleInterTypography,
  TitleExtraBoldTypography,
  VideoWebcam,
  VideoWebcamContainer,
} from "../styles";
import { formatCPF } from "../utils";

type CheckVideoRecordProps = {
  videoUrl: string;
  resetVideo: () => void;
  handleUploadVideo: () => void;
  isLoading: boolean;
  mailingData: MailingData;
  keyword: string;
  formattedDate: string;
  whitelabelName: string;
  videoRef: MutableRefObject<HTMLVideoElement>;
  posterUrl: string;
  showVideo: boolean;
  setShowVideo: (show: boolean) => void;
};

const CheckVideoRecord = ({
  videoUrl,
  resetVideo,
  videoRef,
  isLoading,
  mailingData,
  keyword,
  posterUrl,
  handleUploadVideo,
  showVideo,
  setShowVideo,
}: CheckVideoRecordProps) => {
  const theme = useThemeWhiteLabel();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const previousPosterUrl = useRef<string>(posterUrl);

  useEffect(() => {
    if (posterUrl && canvasRef.current && posterUrl !== previousPosterUrl.current) {
      previousPosterUrl.current = posterUrl;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const image = new Image();
      image.src = posterUrl;
      image.onload = () => {
        const aspectRatio = image.width / image.height;
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientWidth / aspectRatio;
        context.clearRect(0, 0, canvas.width, canvas.height); 
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [posterUrl]);

  useEffect(() => {
    if (showVideo && videoRef.current) {
      videoRef.current.play();
    }
  }, [showVideo, videoRef]);
  return (
    <OnboardingCardLowRadius sx={{ borderRadius: "10px !important" }}>
      <Grid container>
        <Grid item xs={12}>
          <TitleExtraBoldTypography color={theme.primaryColor}>
            Conferir gravação
          </TitleExtraBoldTypography>

          <MailingTextTypography marginTop="-24px">
            Por favor, verifique se o vídeo gravado contém todas as palavras do
            roteiro abaixo:
          </MailingTextTypography>

          <SubTitleInterTypography marginTop="40px" marginBottom="24px">
            Eu <BoldTypography>{mailingData?.nome}</BoldTypography>, portador do
            CPF <BoldTypography>{formatCPF(mailingData?.cpf)}</BoldTypography>,
            autorizo o processo de pré-ativação do benefício do INSS no{" "}
            <BoldTypography>Banco Crefisa</BoldTypography>. A Palavra-chave é{" "}
            <BoldTypography>{keyword}</BoldTypography>.
          </SubTitleInterTypography>
        </Grid>
        <Grid item xs={12} md={8} order={0}>
        {videoUrl && !showVideo && (
            <Box sx={{ position: 'relative' }}>
              <canvas
                ref={canvasRef}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
              <IconButton
                onClick={() => setShowVideo(true)}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '50%',
                }}
              >
                <PlayArrow style={{ fontSize: 48 }} />
              </IconButton>
            </Box>
          )}
          {videoUrl && showVideo && (
            <div>
              <video
                ref={videoRef}
                src={videoUrl}
                controls
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          pl={{ xs: "0px", md: "48px" }}
          order={{ xs: 2, md: 1 }}
        >
          <Stack gap="16px" height="100%" justifyContent="center">
            <Button
              color="primary"
              variant="outlined"
              size="large"
              disabled={isLoading}
              onClick={resetVideo}
              startIcon={
                <LoopOutlined
                  sx={{
                    color: theme.primaryColor,
                  }}
                />
              }
            >
              Trocar vídeo
            </Button>

            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isLoading}
              startIcon={
                isLoading ? <StyledCircularProgress size={16} /> : undefined
              }
              sx={{ paddingInline: "24px" }}
              onClick={() => {
                handleUploadVideo();
              }}
            >
              Continuar
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} order={{ xs: 1, md: 2 }}>
          <SubTitleInterTypography
            mt={{ xs: "24px", md: "40px" }}
            mb={{ xs: "24px", md: "0px" }}
          >
            Caso queira substituir o vídeo, clique no botão{" "}
            <BoldTypography>“Trocar vídeo”</BoldTypography>. Para seguir, clique
            no botão <BoldTypography>“Continuar”</BoldTypography>.
          </SubTitleInterTypography>
        </Grid>
      </Grid>
    </OnboardingCardLowRadius>
  );
};

const VideoRecordStep = ({ setActiveStep }: MailingStepProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [posterUrl, setPosterUrl] = useState(''); 
  const [showVideo, setShowVideo] = useState(false);
  const {
    videoRef,
    videoUrl,
    recording,
    handleStart,
    handleStop,
    setVideoUrl,
    createVideoBase64,
  } = useCamera();

  const {
    permissionGranted: cameraPermissionGranted,
    hasCamera,
    errorMessage: cameraErrorMessage,
  } = useWebcam();
  const {
    permissionGranted: micPermissionGranted,
    hasMic,
    errorMessage: micErrorMessage,
  } = useMicrophone();
  const { whitelabelName } = useWhitelabel();
  const [mailingData, setMailingData] = useState<MailingResponseData>(null);
  const { sendMailingFile, getMailing } = useMailingOnBoardingService();
  const [activeVideoUrl, setActiveVideoUrl] = useState(videoUrl);

  const permissionsGranted = cameraPermissionGranted && micPermissionGranted;
  const errorMessage = cameraErrorMessage || micErrorMessage;
  const hasCameraAndMic = hasCamera && hasMic;

  useEffect(() => {
    if (!mailingData?.additionalInfo?.videoUrl) return;
    setActiveVideoUrl(videoUrl);
    setVideoUrl(mailingData?.additionalInfo?.videoUrl);
  }, [mailingData?.additionalInfo?.videoUrl]);

  useEffect(() => {
    if (!videoUrl) return;
    setActiveVideoUrl(videoUrl);
    generatePosterUrl(videoUrl); 
  }, [videoUrl]);

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getMailing(id)
      .then((response) => {
        setMailingData(response);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
  }, [id]);

  const generatePosterUrl = (videoUrl: string) => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.crossOrigin = 'anonymous'; 

    video.addEventListener('canplay', () => {
      video.currentTime = 0; 
      const canvas = document.createElement('canvas');
      const aspectRatio = video.videoWidth / video.videoHeight;
      canvas.width = video.videoWidth; 
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const posterUrl = canvas.toDataURL('image/jpeg');
      setPosterUrl(posterUrl);
    });
  };

  const resetVideo = () => {
    setVideoUrl(null);
    setActiveVideoUrl(null);
    setPosterUrl(''); 
    setShowVideo(false); 
  };

  const handleUploadVideo = async () => {
    if (!videoUrl) return;
    
    try {
      setIsLoading(true);
      const { base64Content, fileExtension } = await createVideoBase64(videoUrl);
      
      await sendMailingFile({
        fileContent: base64Content,
        fileName: `${id}-video.${fileExtension}`,
        fileType: FileTypeEnum.PersonalDataVideo,
        publicId: id
      });
      
      setActiveStep(EMailingStepsEnum.PersonalDataForm);
    } catch (error) {
      console.error("Erro ao enviar vídeo:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formattedDate = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    locale: ptBR,
  });

  if (!permissionsGranted) {
    return (
      <ContainerContent centered>
        <OnboardingCardLowRadius>
          <SubTitle500Typography marginBlock="64px">
            Favor habilitar o uso da câmera e microfone para gravação do vídeo.
          </SubTitle500Typography>
        </OnboardingCardLowRadius>
      </ContainerContent>
    );
  }

  return (
    <ContainerContent>
      {activeVideoUrl ? (
        <CheckVideoRecord
          videoRef={videoRef}
          videoUrl={activeVideoUrl}
          posterUrl={posterUrl} 
          resetVideo={resetVideo}
          handleUploadVideo={handleUploadVideo}
          isLoading={isLoading}
          formattedDate={formattedDate}
          whitelabelName={whitelabelName}
          mailingData={mailingData?.MailingData}
          keyword={mailingData?.keyword}
          showVideo={showVideo}
          setShowVideo={setShowVideo} 
        />
      ) : (
        <OnboardingCardLowRadius sx={{ position: "relative" }}>
          {recording && (
            <RecordingLabel>
              <SubTitle500Typography>Gravando</SubTitle500Typography>
            </RecordingLabel>
          )}
          <CircleBackground />

          <VideoWebcamContainer>
            {errorMessage && !videoUrl && (
              <CenterBox>
                <Warning style={{ color: Palette.red }} fontSize="small" />
                <SubTitle500Typography sx={{ marginLeft: "8px" }}>
                  {errorMessage}
                </SubTitle500Typography>
              </CenterBox>
            )}
            {permissionsGranted && hasCameraAndMic && (
              <VideoWebcam audio={false} mirrored />
            )}
          </VideoWebcamContainer>

          <SubTitleInterTypography textAlign="center" marginBottom="48px">
            Eu <BoldTypography>{mailingData?.MailingData?.nome}</BoldTypography>
            , portador do CPF{" "}
            <BoldTypography>
              {formatCPF(mailingData?.MailingData?.cpf)}
            </BoldTypography>
            , autorizo o processo de pré-ativação do benefício do INSS no{" "}
            <BoldTypography>Banco Crefisa</BoldTypography>. A Palavra-chave é{" "}
            <BoldTypography>{mailingData?.keyword}</BoldTypography>.
          </SubTitleInterTypography>

          <Button
            color="primary"
            variant="contained"
            size="large"
            sx={{ paddingInline: "24px" }}
            onClick={
              recording
                ? () => {
                    handleStop();
                  }
                : () => {
                    handleStart();
                  }
            }
            startIcon={
              recording ? (
                <Stop sx={{ color: "white" }} />
              ) : (
                <PlayArrow sx={{ color: "white" }} />
              )
            }
          >
            {recording ? "Finalizar gravação" : "Iniciar gravação"}
          </Button>
        </OnboardingCardLowRadius>
      )}
    </ContainerContent>
  );
};

export default VideoRecordStep;