import { useRef, useState } from "react";

const useCamera = () => {
  const previewRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const chunksRef = useRef<BlobPart[]>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [isCaptured, setIsCaptured] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [recording, setRecording] = useState(false);
  const [videoSize, setVideoSize] = useState("");
  const [videoMimeType, setVideoMimeType] = useState("");
  const [originalBlob, setOriginalBlob] = useState<Blob | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  /**
   * Converte a URL do vídeo para base64 preservando os metadados
   * @returns Objeto com o conteúdo base64 e a extensão do arquivo
   */
  const createVideoBase64 = async (url: string): Promise<{ 
    base64Content: string; 
    fileExtension: string; 
    mimeType: string;
  }> => {
    try {
      // Busca o blob a partir da URL
      const response = await fetch(url);
      const blob = await response.blob();
      
      // Obtém o tipo MIME do blob ou usa um padrão
      const mimeType = blob.type || "video/mp4";
      
      // Extrai a extensão do MIME type para o nome do arquivo
      const fileExtension = mimeType.split('/')[1]?.split(';')[0] || 'mp4';
      
      // Converte para base64
      const base64Content = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
      
      return { base64Content, fileExtension, mimeType };
    } catch (error) {
      console.error("Erro ao processar vídeo:", error);
      throw error;
    }
  };

  const handleStart = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    
    const options = {
      mimeType: "video/webm;codecs=vp8,opus",
    };
    
    let recorder;
    try {
      recorder = new MediaRecorder(stream, options);
    } catch (e) {
      console.log("Formato webm não suportado, usando o formato padrão");
      recorder = new MediaRecorder(stream);
    }
    
    setMediaRecorder(recorder);

    if (previewRef.current) {
      previewRef.current.srcObject = stream;
      previewRef.current.play();
    }

    recorder.start();
    chunksRef.current = [];

    recorder.ondataavailable = (e) => {
      chunksRef.current.push(e.data);
    };

    setRecording(true);
  };
  const handleStop = () => {
    if (mediaRecorder) {
      mediaRecorder.onstop = () => {
        const mimeType = mediaRecorder.mimeType || "video/webm";
        setVideoMimeType(mimeType);
        
        const blob = new Blob(chunksRef.current, { type: mimeType });
        setOriginalBlob(blob);
        
        const sizeInMB = blob.size / (1024 * 1024);
        setVideoSize(sizeInMB.toFixed(2));
        
        const videoUrl = URL.createObjectURL(blob);
        setVideoUrl(videoUrl);

        if (videoRef.current) {
          videoRef.current.load();
        }
      };
      mediaRecorder.stop();
    }
    if (previewRef.current && previewRef.current.srcObject) {
      const tracks = (previewRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      previewRef.current.srcObject = null;
    }

    setRecording(false);
  };
  const handleCapture = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      const aspectRatio = 4 / 3;
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      let width = videoWidth;
      let height = width / aspectRatio;
      if (height > videoHeight) {
        height = videoHeight;
        width = height * aspectRatio;
      }
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      context?.drawImage(videoRef.current, 0, 0, width, height);

      setIsCaptured(true);
      if (videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  };
  return {
    previewRef,
    videoRef,
    chunksRef,
    mediaRecorder,
    videoUrl,
    recording,
    videoSize,
    videoMimeType,
    originalBlob,
    canvasRef,
    isCaptured,
    setIsCaptured,
    handleCapture,
    handleStart,
    handleStop,
    setVideoUrl,
    createVideoBase64,
  };
};

export default useCamera;
