import { Grid, Stack } from "@mui/material";
import { BoldTypography } from "../../../styles";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const PepContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          PESSOAS EXPOSTAS POLITICAMENTE (PEP) - NÃO SE APLICA
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Nos termos das Circulares nºs 3.461/2009 e 3.654/2013 do Banco Central
          do Brasil (BACEN), consideram-se pessoas expostas politicamente (PEP)
          os agentes públicos que desempenham ou tenham desempenhado, nos
          últimos 5 (cinco) anos, no Brasil ou em países, territórios e
          dependências estrangeiras, cargos, empregos ou funções públicas
          relevantes, assim como seus representantes, familiares e outras
          pessoas de seu relacionamento próximo.
        </ContractText>

        <ContractText style={{ marginTop: "4px" }}>
          <BoldTypography>
            *O signatário da presente ficha cadastral é pessoa exposta
            politicamente?
          </BoldTypography>
        </ContractText>
        <Stack direction="row" gap="16px" marginBottom="8px">
          <MailingContractCheckbox label="Não" />
          <MailingContractCheckbox label="Sim" />
        </Stack>

        <ContractText>
          Em caso positivo, é necessário preencher e assinar o ANEXO PEP, que
          formará parte integrante desta ficha cadastral.
        </ContractText>
      </Grid>
    </>
  );
};

export default PepContractSection;
