import { Stack } from "@mui/material";
import { PropsWithChildren, useRef } from "react";
import CrefisaLogo from "../../../../assets/crefisa_logotipo.png";
import { MailingData } from "../../../../shared/models/backend/mailing/mailing";
import MailingContractCheckbox from "../ContractCheckbox";
import AccountProposalContractSection from "./AccountProposalSection";
import CreditCardContractSection from "./CreditCardSection";
import CreditCardTermsContractSection from "./CreditCardTermsSection";
import CurrentAccountContractSection from "./CurrentAccountSection";
import DateAndSignContractSection from "./DateAndSignSection";
import DeclarationsContractSection from "./DeclarationsSection";
import IdentificationProofContractSection from "./IdentificationProofSection";
import MagneticCardContractSection from "./MagneticCardSection";
import MagneticCardTermsContractSection from "./MagneticCardTermsSection";
import PepContractSection from "./PepSection";
import PersonalDataRegisterContractSection from "./PersonalDataRegisterSection";
import PersonalDataContractSection from "./PersonalDataSection";
import ReceiptContractSection from "./ReceiptSection";
import {
  BlankStripContainer,
  BlankStripText,
  ContractCard,
  ContractContainer,
  ContractFooterText,
  ContractGrid,
  ContractLogo,
  TitleTypography,
} from "./styles";
import TransferAuthorizationContractSection from "./TransferAuthorizationSection";

type ContractShellProps = {
  withContractorType?: boolean;
  text?: string;
} & PropsWithChildren;

const ContractShell = ({
  withContractorType,
  text,
  children,
}: ContractShellProps) => {
  const contractPageContentRef = useRef<HTMLDivElement>();
  const stripHeight = 32;
  const footerHeight = 60;
  const pageContentHeight = contractPageContentRef?.current?.offsetHeight || 0;
  const availableHeight = 1756 - pageContentHeight - footerHeight;
  const availableWidth = contractPageContentRef?.current?.offsetWidth || 0;
  const diagonalWidth =
    Math.sqrt(availableWidth ** 2 + availableHeight ** 2) - stripHeight;
  const angleRadians = Math.atan(
    (availableHeight - stripHeight * 2) / availableWidth
  ); // tan^-1(height / width)
  const angleDegrees = angleRadians * (180 / Math.PI);
  // const { whitelabelLogo, whitelabelName } = useWhitelabel();
  const whitelabelLogo = CrefisaLogo;
  const whitelabelName = "crefisa";
  return (
    <ContractCard>
      <ContractContainer ref={contractPageContentRef}>
        <Stack alignItems="center" height={80}>
          <ContractLogo src={whitelabelLogo} alt={`${whitelabelName} logo`} />

          <TitleTypography>
            Termo de Adesão a Produtos e Serviços
          </TitleTypography>
          {withContractorType && (
            <Stack direction="row" gap="32px" mb="16px">
              <MailingContractCheckbox label="Titular" checked />
              <MailingContractCheckbox label="Reprentante Legal" />
              <MailingContractCheckbox label="Procurador" />
              <MailingContractCheckbox label="Terceiro" />
            </Stack>
          )}
          {text && <TitleTypography>{text}</TitleTypography>}
        </Stack>
        <ContractGrid container>{children}</ContractGrid>
        
      </ContractContainer>
      {availableHeight > 150 && (
        <BlankStripContainer>
          <BlankStripText
            style={{
              width: `${diagonalWidth}px`,
              transform: `rotate(-${angleDegrees}deg)`,
            }}
          >
            EM BRANCO
          </BlankStripText>
        </BlankStripContainer>
      )}
      <ContractFooterText>MOD 01_2025</ContractFooterText>
    </ContractCard>
  );
};

export type MailingContractDataProps = {
  mailingData: MailingData;
  userData: any;
};

const MailingContract = ({
  page,
  mailingData,
  userData,
}: {
  page: number;
} & MailingContractDataProps) => {
  if (page === 1) {
    return (
      <ContractShell withContractorType>
        <PersonalDataContractSection
          mailingData={mailingData}
          userData={userData}
        />
        <MagneticCardContractSection
          mailingData={mailingData}
          userData={userData}
        />
        <CreditCardContractSection
          mailingData={mailingData}
          userData={userData}
        />
        <CurrentAccountContractSection />
      </ContractShell>
    );
  }

  if (page === 2) {
    return (
      <>
        <ContractShell>
          <TransferAuthorizationContractSection />
          <IdentificationProofContractSection
            mailingData={mailingData}
            userData={userData}
          />
          <ReceiptContractSection />
          <DateAndSignContractSection />
        </ContractShell>
      </>
    );
  }

  if (page === 3) {
    return (
      <ContractShell text="ANEXO I - TERMOS E CONDIÇÕES DOS PRODUTOS">
        <AccountProposalContractSection />
        <MagneticCardTermsContractSection />
        <CreditCardTermsContractSection
          mailingData={mailingData}
          userData={userData}
          page={3}
        />
      </ContractShell>
    );
  }

  if (page === 4) {
    return (
      <ContractShell>
        <CreditCardTermsContractSection
          mailingData={mailingData}
          userData={userData}
          page={4}
        />
        <PersonalDataRegisterContractSection page={4} />
        
      </ContractShell>
    );
  }

  if (page === 5) {
    return (
      <ContractShell>
         <PersonalDataRegisterContractSection page={5} />
         <PepContractSection />
        <DeclarationsContractSection page={4} />
        <DeclarationsContractSection page={5} />
      </ContractShell>
    );
  }

  if (page === 6) {
    return (
      <ContractShell>
        <DeclarationsContractSection page={6} />
      </ContractShell>
    );
  }

  // if (page === 7) {
  //   return (
  //     <ContractShell>
  //       <DeclarationsContractSection page={7} />
  //     </ContractShell>
  //   );
  // }

  // if (page === 8) {
  //   return (
  //     <ContractShell>
  //       <DeclarationsContractSection page={8} />
  //     </ContractShell>
  //   );
  // }

  return <p>Pagina de Contrato invalida!</p>;
};

export default MailingContract;
