const Constants = {
  FREE_PLAN_ID: 1,
  ENTERPRISE_PLAN_ID: 6,
  VIDEO_ONBOARDING_CONFIGS_LOCAL_STORAGE_KEY: 'VIDEO_ONBOARDING_CONFIGS_LOCAL_STORAGE_KEY',
  CONSULTAI_WHITELABEL_ID: 1,
  ALLOWED_TEST_CARD_NUMBERS : [
    "4000000000000010", // Cartão de sucesso. Qualquer operação com esse cartão é realizada com sucesso
    "4000000000000028", // Cartão de falha. Qualquer transação retorna como "não autorizada"
    "4000000000000036", // Cartão Processing -> Sucesso. A transação recebe o status de erro e, posteriormente, é confirmada pela adquirente
    "4000000000000044", // Cartão Processing -> Falha. A transação recebe o status de erro e, posteriormente, não é encontrada na adquirente, sendo caracterizada como "falha"
    "4000000000000077", // Cartão Sucesso -> Processing -> Sucesso
    "4000000000000093", // Cartão Sucesso -> Processing -> Sucesso (primeira operação)
    "4000000000000051", // Cartão Processing -> Cancelado
    "4000000000000069", // Cartão Paid -> Chargedback
  ]
};

export { Constants };
