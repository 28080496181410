import {useContext, useRef, useState} from "react";
import Modal from "../Modal";
import {ValidationUtils} from "../../utils/validation-utils";
import {useUnitService} from "../../shared/services/useUnitService";
import {useCardsService} from "../../shared/services/useCardsService";
import {CreateCardResponse} from "../../shared/models/responses/cards-response";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import PagarmeApiContext from "../../shared/services/context/pagarme-api-context";
import {CreateCardRequest} from "../../shared/models/requests/subscription-request";
import {ApiConfig} from "../../constants/ApiConfig";
import CardForm from "./CardForm";
import AddressForm from "./AddressForm";
import CreateCardData from "./CreateCardData";
import CreateCardAddressData from "./CreateCardAddressData";
import {AccountContext} from "../../shared/context/AccountContext";
import {Constants} from "../../constants/Constants";

interface CreateCreditCardModalProps {
    onCancel: () => void;
    onSave: (card?: CreateCardResponse) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    onClose: () => void;
}

const CreateCreditCardModal = (props: CreateCreditCardModalProps) => {
    const {onCancel, onSave, loading, setLoading, onClose} = props;
    const [address, setAddress] = useState<CreateCardAddressData>();
    const [currentStep, setCurrentStep] = useState<"card" | "address">("card");
    const cardRef = useRef<CreateCardData>();
    const {buscarIdPagarmeUnidade} = useUnitService();
    const {requestSaveCard} = useCardsService();
    const accountContext = useContext(AccountContext);
    const context = useContext(PagarmeApiContext);

    const pagarmeClient = axios.create({
        timeout: context?.timeout ?? 60000,
        baseURL: context?.baseUrl,
        headers: {
            "Content-Type": "application/json",
        },
    });

    const isAddressValid = () =>
        ValidationUtils.validateCep(address?.zipCode, true) &&
        ValidationUtils.validateState(address?.state, true) &&
        ValidationUtils.validateCity(address?.city, true) &&
        ValidationUtils.validateAddress(address?.addressName, true) &&
        ValidationUtils.validateAddressNumber(address?.addressNumber, true) &&
        ValidationUtils.validateNeighborhood(address?.neighborhood, true);

    const onCardFormNext = (card: CreateCardData) => {
        cardRef.current = card;
        setCurrentStep("address");
    };

    const onAddressFormBack = () => setCurrentStep("card");

    const saveCard = async () => {
        if (!isAddressValid()) return;

        setLoading(true);

        try {
            if (
                accountContext.ids &&
                accountContext.ids.unitId !== null &&
                accountContext.ids.unitId !== undefined
            ) {
                const customerId = (
                    await buscarIdPagarmeUnidade(accountContext.ids.unitId)
                ).id;
                accountContext.setIds({
                    ...accountContext.ids,
                    customerId: customerId,
                });
                const card = cardRef.current;
                const cardNumber = card.cardNumber?.replaceAll(" ", "");
                const cardRequest: CreateCardRequest = {
                    number: cardNumber,
                    holder_name: card.cardHolderName,
                    holder_document: card.cardHolderDocument
                        ?.replaceAll(".", "")
                        ?.replaceAll("/", "")
                        .replaceAll("-", ""),
                    exp_month: card.cardExpirationMonth,
                    exp_year: card.cardExpirationYear,
                    cvv: card.cardCvv,
                    billing_address: {
                        line_1: address.addressName,
                        line_2: address.neighborhood,
                        zip_code: address.zipCode?.replaceAll("-", ""),
                        city: address.city,
                        state: address.state,
                        country: "BR",
                    },
                };
                if (ApiConfig.Environment != 'PRD') {
                    if (!Constants.ALLOWED_TEST_CARD_NUMBERS.includes(cardNumber)) {
                        toast.error("O cartão informado não é válido para esse ambiente.");
                        return;
                    }
                }
                const createdCardToken = (
                    await pagarmeClient.post(
                        `/v5/tokens?appId=${ApiConfig.Pagarme.publicToken}`,
                        {
                            card: cardRequest,
                            type: "card",
                        }
                    )
                )?.data;
                if (
                    accountContext.ids.accountId !== undefined &&
                    accountContext.ids.accountId !== null
                ) {
                    await requestSaveCard({
                        accountId: accountContext.ids.accountId,
                        name: card.cardName,
                        pagarMeToken: createdCardToken.id,
                        unityId: accountContext.ids.unitId,
                        cardAddress: {
                            ...cardRequest.billing_address,
                            line1: cardRequest.billing_address.line_1,
                            line2: cardRequest.billing_address.line_2,
                            zipCode: cardRequest.billing_address.zip_code,
                        },
                    });
                    toast.success("Cartão cadastrado com sucesso!");
                }
                setTimeout(() => {
                    onSave(createdCardToken);
                    onCancel();
                }, 1000);
            }
        } catch {
            toast.error(
                "Erro ao cadastrar cartão. Verifique os dados e tente novamente."
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title="Informações do cartão" onClose={onClose}>
            <div style={{width: '100%'}}>
                {currentStep === "card" && (
                    <CardForm
                        card={cardRef.current}
                        onCancel={onCancel}
                        onNext={onCardFormNext}
                    />
                )}
                {currentStep === "address" && (
                    <AddressForm
                        loading={loading}
                        address={address}
                        setAddress={setAddress}
                        onBack={onAddressFormBack}
                        onSaveCard={saveCard}
                    />
                )}
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    limit={1}
                    theme="colored"
                />
            </div>
        </Modal>
    );
};

export default CreateCreditCardModal;
